.Footer {
  padding: 0.5em;
  text-align: center;

  .heart-symbol,
  .code-symbol {
    font-size: 90%;
  }

  .code-symbol {
    color: grey;
  }

  .heart-symbol {
    color: red;
  }
}
