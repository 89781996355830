@import url("https://fonts.googleapis.com/css?family=Lato:400");
@import "styles/buttons";
@import "styles/links";
@import "styles/text";
@import "styles/variables";
@import "styles/inputs";

:root {
  color-scheme: light dark;
}

html {
  font-size: 93.8%;
} /*15px*/

body {
  background: var(--background-primary);
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.75;
  color: var(--color-primary);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: 0.8rem;
}
