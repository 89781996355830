$mobile-width: 40em;

.Page {
  margin-left: 0.5em;
}

.command-search {
  display: flex;
  align-items: baseline;
  margin-bottom: 1em;

  @media only screen and (min-width: $mobile-width) {
    font-size: 2em;

    .command-search-input {
      width: 400px;
    }
  }

  @media only screen and (max-width: $mobile-width) {
    &,
    h2 {
      font-size: 1.2em;
    }

    .command-search-input {
      width: 100%;
    }
  }

  .command-search-input {
    font-family: var(--font-primary);
    color: var(--color-primary);
    margin-left: 0.3em;
    font-size: inherit;
    border: none;
    border-bottom: 1px solid var(--color-primary);
    border-radius: 0px;
    padding: none;
    outline: none;
    background-color: var(--background-primary);
  }
}

.admin-toggle {
  display: flex;
  align-items: center;

  label {
    margin-left: 0.5em;
  }
}

.no-matching {
  margin-top: 1em;
}
