$mobile-width: 40em;

.SettingDisplay {
  display: flex;
  flex-direction: column;
  justify-content: baseline;

  margin-bottom: 1em;

  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
    padding-left: 4px;

    label {
      font-size: 1.1em;
    }
  }

  .select-input,
  .RolePicker,
  .number-input,
  .text-input {
    @media only screen and (min-width: $mobile-width) {
      width: 25%;
    }

    @media only screen and (max-width: $mobile-width) {
      width: 80%;
    }
  }
}
