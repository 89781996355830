.UserDisplay {
  display: flex;
  align-items: center;

  justify-self: end;
  padding-right: 20px;

  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .settings-icon {
    margin-right: 0.5em;
  }

  button {
    color: red;

    &:active {
      color: darkred;
    }
  }
}
