@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.gowonify {
  display: flex;
  align-items: center;

  h1 {
    font-weight: bold;
    font-family: "Lato", sans-serif;
    margin-left: 0.2em;
    font-size: 100px;
    color: #02bca1;
  }
}

@media only screen and (max-width: 1000px) {
  .spotify-logo {
    height: 100px;
    width: 100px;
  }

  .gowonify h1 {
    font-size: 50px;
  }

  .success {
    font-size: 150%;
  }
}

.information {
  margin-top: 2em;
  text-align: center;
}
