button:not(.link),
a.button {
  color: var(--color-primary);
  border: 2px var(--color-primary) solid;
  padding: 0.5em 1.25em;
  border-radius: 25em;
  transition: all 150ms;
  background: var(--background-primary);

  &:hover:not(:disabled) {
    cursor: pointer;
    background: var(--background-darker);
  }

  &:disabled {
    color: grey;
    border-color: grey;
  }

  &.button-discord {
    border-color: var(--discord-blurple);
  }

  &.button-lastfm {
    border-color: var(--lastfm-red);
  }

  &.button-patreon {
    border-color: var(--patreon-orange);
  }
}

a.button {
  text-decoration: none;
}

button.link {
  border: none;
  padding: 0;
  transition: all 150ms;
  background: transparent;
  font-size: 100%;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
