input,
select {
  color: var(--color-primary);
  background: var(--background-lighter);
  border: 1px var(--color-primary) solid;
  border-radius: 10px;
  font-size: 1.1em;
  padding: 0.4em;
}

input[type="text"] {
  &.small {
    width: 30px;
    height: 30px;
    text-align: center;
  }
}

// ReactSelect input doesn't want to use the default colour for some reason
[id^="react-select-"][id$="-input"] {
  color: var(--color-primary) !important;
}
