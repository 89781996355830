.DiscordMessage {
  margin-bottom: 1rem;
  display: flex;

  .profile-picture {
    margin-top: 2px;
    width: 2.5em;
    height: 2.5em;
    clip-path: circle();
  }

  .header {
    font-size: 1em;
    overflow: hidden;

    .display-name {
      margin-right: 0.5em;
    }

    .timestamp {
      font-size: 75%;
      color: rgb(116, 127, 141);
    }
  }

  .right {
    margin-left: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .message-content {
    font-size: 95%;
    text-align: left;
  }
}
