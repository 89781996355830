img {
  height: 50vh;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
