.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
