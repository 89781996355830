$mobile-width: 40em;

.CommandHelp {
  .command-title {
    .subtitle {
      font-size: 50%;
    }
  }

  h5 {
    font-size: 1.1em;
  }

  .help-description,
  .aliases,
  .variations {
    margin-bottom: 10px;
  }

  .aliases {
    display: flex;
    align-items: center;

    .alias {
      margin-left: 5px;
    }
  }

  .usage-title {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: $mobile-width) {
    .variations-content {
      margin-left: 20px;
    }

    .usage-content {
      margin-left: 20px;
    }
  }
}

.CommandHelp:not(.in-parent) {
  border: 2px var(--background-darker) solid;
  margin: 20px 5px 5px 5px;
  padding: 5px 20px 0px 20px;
  border-radius: 1em;

  &:hover {
    transition: 100ms ease-in-out;
    cursor: pointer;
  }

  &.expanded {
    box-shadow: var(--background-darker) 7px 7px 3px;
  }

  @media only screen and (min-width: $mobile-width) {
    width: 50%;
  }

  @media only screen and (max-width: $mobile-width) {
    margin-left: 0;
    font-size: 0.7em;

    .command-title {
      font-size: 1.3em;
    }
  }

  .children {
    margin-top: 20px;
  }
}

.CommandHelp.in-parent {
  margin-bottom: 20px;
  font-size: 0.9em;

  .command-title {
    font-size: 1.4em;
  }
}
