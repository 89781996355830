.Page {
  padding: 0.5em;
}

.Page.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
