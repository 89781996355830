.HomePage {
  display: flex;

  .Page {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .Card {
    width: 23%;
    height: 300px;
  }

  .discord-username {
    color: var(--gowon-development-pink);
  }

  .content {
    margin-top: 10%;

    overflow: hidden;
    border-radius: 2em;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .buttons {
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0em 1em;

    .button {
      font-size: 90%;
      min-width: 250px;
      margin-bottom: 0.8rem;
    }
  }
}
