.text-inline {
  font-family: monospace;
  color: var(--color-secondary);
  background: var(--background-lighter);
  padding: 1.5px 4px;
  border-radius: 4px;
}

.underline {
  text-decoration: underline;
}

.subtext {
  color: var(--color-secondary);
}
